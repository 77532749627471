<template>
  <v-container class="contact-wrapper">
    <h3 class="contact-title text-center">{{ $t("contact.title") }}</h3>
    <v-form class="contact-form" v-model="valid" lazy-validation ref="form">
      <v-container>
        <v-row>
          <v-col cols="12" class="pa-0 mt-5">
            <v-text-field
              :label="$t('contact.firstNamePlc')"
              dense
              outlined
              dark
              v-model="firstName"
              :rules="firstNameRules"
              autocomplete="off"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-text-field
              :label="$t('contact.lastNamePlc')"
              dense
              outlined
              dark
              v-model="lastName"
              :rules="lastNameRules"
              autocomplete="off"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-text-field
              :label="$t('contact.emailPlc')"
              dense
              outlined
              dark
              v-model="email"
              :rules="emailRules"
              autocomplete="off"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="gender"> 
          <v-col cols="12" class="pa-0">
            <v-text-field
              dense
              outlined
              dark
              v-model="genderEmpty"
              :rules="genderRules"
              autocomplete="off" 
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0">
            <vue-tel-input-vuetify
              :label="$t('contact.phonePlc')"
              placeholder="Telephone, e.g."
              v-model="phone"
              dense
              outlined
              filled
              @validate="numberValidate"
              :rules="numberRules"
              autocomplete="off"
            ></vue-tel-input-vuetify>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-btn 
              class="btn-register" 
              @click="sendData"
            >
              <span v-if="!isLoading">{{ $t("contact.buttonText") }}</span>
               <v-progress-circular
                v-else
                indeterminate
                color="#012e4d"
              ></v-progress-circular>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-container class="terms pa-0 mb-5">
              *By submitting you confirm that you’ve read and accepted the
              <a href="/privacy.html" target="_blank">privacy policy</a>
              and
              <a href="/terms.html" target="_blank">terms of conditions</a
              >.<br />
              **By submitting this form, I agree to receive all marketing
              material by email, SMS and telephone.<br />
              ***All Trading carries risk.
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'

import 'sweetalert2/src/sweetalert2.scss'

export default {
  name: "ContactForm",
  data() {
    return {
      isLoading: false,
      dataStatus: null,
      valid: false,
      firstName: "",
      lastName: "",
      email: "",
      userIp: null,
      genderEmpty: "",
      firstNameRules: [
        (v) => !!v || "Please enter your first name (No numbers)",
        (v) => !/\d/.test(v) || "Please enter your first name (No numbers)",
      ],
      lastNameRules: [
        (v) => !!v || "Please enter your last name (No numbers)",
        (v) => !/\d/.test(v) || "Please enter your last name (No numbers)",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      numberRules: [
        (v) => !!v || "Number can not be empty",
        (v) => this.phoneIsValid || "Number is not valid",
      ],
      genderRules: [
        (v) => v === "" || false
      ],
      phone: null,
      phoneIsValid: false,
      fullNumber: null,
    };
  },
  async created() {
    try {
      await fetch("https://api.ipify.org?format=json")
        .then((x) => x.json())
        .then(({ ip }) => {
          this.userIp = ip;
        });
    } catch (error) {
      throw error;
    }
  },
  methods: {
    numberValidate(tel) {
      this.phoneIsValid = tel.valid;

      if (this.phoneIsValid) {
        this.fullNumber = tel.number.international;
      } else {
        this.fullNumber = null;
      }
    },
    async sendData() {
      let isValid = this.$refs.form.validate();

      if (isValid) {
        this.isLoading = true;

        let url_string = window.location.href;
        let url = new URL(url_string);

        let ai = url.searchParams.get("ai") || "2958033";
        let ci = url.searchParams.get("ci") || "1";
        let gi = url.searchParams.get("gi") || "23";
        // let gi = "23"; // test
        let h_username = url.searchParams.get("username");
        let h_password = url.searchParams.get("password");

        try {
          const responce = await fetch("/sender.php", {
            method: "POST",
            body: JSON.stringify({
              "x-trackbox-username": h_username,
              "x-trackbox-password": h_password,
              "ai": ai,
              "ci": ci,
              "gi": gi,
              "userip": this.userIp,
              // "userip": '77.234.43.134', // test api
              "firstname": this.firstName,
              "lastname": this.lastName,
              "email": this.email,
              "password": "Aa12345!",
              "phone": this.fullNumber.split(' ').join(''),
              "gender": this.genderEmpty
            }),
          });
          const data = await responce.json();

          const successPage = data.data;

          if (data.status) {
            Swal.fire({
              title: 'Success!',
              text: 'Do you want to continue',
              icon: 'success',
              confirmButtonText: 'Let\'s start'
            }).then(function() {
              window.location.href = successPage;
            });
          } else {
           Swal.fire({
              title: 'Error!',
              text: 'Do you want to continue',
              icon: 'error',
              confirmButtonText: 'Close'
            });
          }

          this.dataStatus = data.status;

          this.isLoading = false;

          console.log('data', data)
        } catch (error) {
          throw error;
        }
      } else {
        this.$refs.form.validate();
      }
    },
  },
};
</script>

<style scoped>
.gender {
  position: fixed;
    top: -5000px;
    opacity: 0.1;
}
</style>