import { render, staticRenderFns } from "./whyInvest.vue?vue&type=template&id=563a2c73&scoped=true&"
var script = {}
import style0 from "./whyInvest.vue?vue&type=style&index=0&id=563a2c73&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "563a2c73",
  null
  
)

export default component.exports