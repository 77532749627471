import VueRouter from "vue-router";

import products from '../pages/products';
import main from '../pages/main';
import markers from '../pages/markers';
import support from '../pages/support';
import aboutus from '../pages/aboutus';
import whyChoose from '../pages/whyChoose';
import security from '../pages/security';
import accountManagement from '../pages/accountManagement';
import whyInvest from '../pages/whyInvest';
import blockChain from '../pages/blockChain';
import ourProducts from '../pages/ourProducts';
import buySell from '../pages/buySell';
import allTokens from '../pages/allTokens';
import lending from '../pages/lending';
import loan from '../pages/loan';
import xapomarketsPool from '../pages/xapomarketsPool';
import OTCTra from '../pages/OTCTra';
import login from '../pages/login';
import register from '../pages/register';
import starking from '../pages/staking';
import contactUs from '../pages/cont-us';
import privacy from '../pages/privacy';
import terms from '../pages/terms';
import cookie from '../pages/cookie';
import p2p from '../pages/p2p';
export default new VueRouter({
    mode:'history',
    routes:[
        {
            path:'/products/P2P',
            component: p2p
        },
        {
            path:'/cookies-policy',
            component: cookie
        },
        {
            path:'/terms-and-conditions',
            component: terms
        },
        {
            path:'/contact-us',
            component: contactUs
        },
        {
            path:'/privacy-policy',
            component: privacy
        },

        {
            path:'/programs-main-archive',
            component: products
        },
        {
            path:'/products/staking',
            component: starking
        },
        {
            path:'/register',
            component: register
        },
        {
            path:'/login',
            component: login
        },
        {
            path:'/products/OTC-Trading',
            component: OTCTra
        },
        {
            path:'/products/xapomarkets-pool',
            component: xapomarketsPool
        },
        {
            path:'/products/loan',
            component: loan
        },
        {
            path:'/products/allTokens',
            component: allTokens
        },
        {
            path:'/products/lending',
            component: lending
        },
        {
            path:'/products/buy-sell-crypto',
            component: buySell
        },
        {
            path:'/our-products',
            component: ourProducts
        },
        {
            path:'/blockchain-transaction-works',
            component: blockChain
        },
        {
            path:'/why-invest-cryptocurrency',
            component: whyInvest
        },
        {
            path:'/account-management',
            component: accountManagement
        },
        {
            path:'/security',
            component: security
        },
        {
            path:'/why-choose-xapomarkets',
            component: whyChoose
        },
        {
            path:'/about-us',
            component: aboutus
        },
        {
            path:'/support',
            component: support
        },
        {
            path:'/markets',
            component: markers
        },
        {
            path:'/',
            component: main
        },
    ]
})




