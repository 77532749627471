var lastId,topMenu=$("nav.onepage, .footer-menu.onepage"),topMenuHeight=$("header").height();menuItems=topMenu.find("a"),scrollItems=menuItems.map(function(){var item=$($(this).attr("href"));if(item.length){return item;}});menuItems.click(function(e){var href=$(this).attr("href"),offsetTop=href==="#"?0:$(href).offset().top-topMenuHeight+1;$('html, body').stop().animate({scrollTop:offsetTop},900);e.preventDefault();});$(window).scroll(function(){var fromTop=$(this).scrollTop()+topMenuHeight;var cur=scrollItems.map(function(){if($(this).offset().top<fromTop)
    return this;});cur=cur[cur.length-1];var id=cur&&cur.length?cur[0].id:"";if(lastId!==id){lastId=$();menuItems.parent().removeClass("active").end().filter("[href=#"+id+"]").parent().addClass("active");}
    $(window).scroll(function(){if($(window).scrollTop()>topMenuHeight){$("header").addClass('fixed');}else{$("header").removeClass('fixed');}});});


    $(function() {
 
        $(window).scroll(function() {
         
        if($(this).scrollTop() != 0) {
         
        $('#toTop').fadeIn();
         
        } else {
         
        $('#toTop').fadeOut();
         
        }
         
        });
         
        $('#toTop').click(function() {
         
        $('body,html').animate({scrollTop:0},800);
         
        });
         
        });
         



        $(function () {
            $('.spoiler-body').hide(300);
            $(document).on('click','.card-header',function (e) {
                e.preventDefault()
                $(this).parents('.card').toggleClass("active").find('.collapse').slideToggle();
            })
        })

        $(document).ready(function() {
            $(".menu-icon").click(function() {
              $(this).toggleClass("show");
              $('.onepage').slideToggle(300, function(){
                if($(this).css('display') === "none"){
                  $(this).removeAttr('style');
                }
              });
            });
          });




        $(document).ready(function() {
            $("#by-email-tab").click(function() {
$("#by-email").addClass("active");
if($("#by-email").hasClass("active")){
    $("#by-phone").removeClass("active");
};
            });

            
        });
        
        $(document).ready(function() {
            $("#by-phone-tab").click(function() {
$("#by-phone").addClass("active");
if($("#by-phone").hasClass("active")){
    $("#by-email").removeClass("active");
};
            });

            
        });


    