<template> <v-app>
      <mainSect/>
<second/>
<third/>
<four/>
<five/>
<six/>
<seven/>
<eight/>
 </v-app>
</template>
<script>
import mainSect from "./../components/sections/mainSect";
import second from "./../components/sections/second";
import third from "./../components/sections/third";
import four from "./../components/sections/four";
import five from "./../components/sections/five";
import six from "./../components/sections/six";
import seven from "./../components/sections/seven";
import eight from "./../components/sections/eight";
export default {

  components: {
mainSect,
second,
third,
four,
five,
six,
seven,
eight,
  },
  }

</script>